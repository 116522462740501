export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 78,
  w: 337,
  h: 337,
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 28, s: [100] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [300, 90, 0],
              to: [0, -12.667, 0],
              ti: [0, 12.667, 0]
            },
            { t: 24, s: [300, 14, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 14,
              s: [71, 71, 100]
            },
            { t: 20, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.089, -0.019],
                    [0, 0],
                    [0, 0],
                    [0.309, -0.309],
                    [0.084, -0.425],
                    [0, 0],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0.017, 0.088],
                    [0, 0],
                    [0.309, 0.309],
                    [0.43, 0.09],
                    [0, 0],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07],
                    [-0.089, 0.019],
                    [0, 0],
                    [-0.309, 0.309],
                    [-0.084, 0.425],
                    [0, 0],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [-0.017, -0.088],
                    [0, 0],
                    [-0.309, -0.309],
                    [-0.43, -0.09],
                    [0, 0],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.43, 0.09],
                    [-0.309, 0.309],
                    [0, 0],
                    [-0.017, 0.088],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [0, 0],
                    [-0.084, -0.425],
                    [-0.309, -0.309],
                    [0, 0],
                    [-0.089, -0.019],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07],
                    [0, 0],
                    [0.43, -0.09],
                    [0.309, -0.309],
                    [0, 0],
                    [0.017, -0.088],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0, 0],
                    [0.084, 0.425],
                    [0.309, 0.309],
                    [0, 0],
                    [0.089, 0.019],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07]
                  ],
                  v: [
                    [3.186, 0.384],
                    [2.299, 0.564],
                    [2.299, 0.564],
                    [1.166, 1.174],
                    [0.564, 2.299],
                    [0.384, 3.186],
                    [0.25, 3.411],
                    [0, 3.5],
                    [-0.25, 3.411],
                    [-0.384, 3.186],
                    [-0.564, 2.299],
                    [-1.166, 1.174],
                    [-2.299, 0.564],
                    [-3.186, 0.384],
                    [-3.412, 0.246],
                    [-3.5, 0],
                    [-3.412, -0.246],
                    [-3.186, -0.384],
                    [-2.299, -0.564],
                    [-1.166, -1.174],
                    [-0.564, -2.299],
                    [-0.384, -3.186],
                    [-0.25, -3.411],
                    [0, -3.5],
                    [0.25, -3.411],
                    [0.384, -3.186],
                    [0.564, -2.299],
                    [1.166, -1.174],
                    [2.299, -0.564],
                    [3.186, -0.384],
                    [3.412, -0.246],
                    [3.5, 0],
                    [3.412, 0.246]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.898010253906, 0.898010253906, 0.898010253906, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 30, s: [80] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [67, 84, 0],
              to: [0, -9.667, 0],
              ti: [0, 9.667, 0]
            },
            { t: 24, s: [67, 26, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 15,
              s: [84, 84, 100]
            },
            { t: 21, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.127, -0.027],
                    [0, 0],
                    [0, 0],
                    [0.441, -0.441],
                    [0.12, -0.607],
                    [0, 0],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0.024, 0.126],
                    [0, 0],
                    [0.441, 0.441],
                    [0.615, 0.128],
                    [0, 0],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1],
                    [-0.127, 0.027],
                    [0, 0],
                    [-0.441, 0.441],
                    [-0.12, 0.607],
                    [0, 0],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [-0.024, -0.126],
                    [0, 0],
                    [-0.441, -0.441],
                    [-0.615, -0.128],
                    [0, 0],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.615, 0.128],
                    [-0.441, 0.441],
                    [0, 0],
                    [-0.024, 0.126],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [0, 0],
                    [-0.12, -0.607],
                    [-0.441, -0.441],
                    [0, 0],
                    [-0.127, -0.027],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1],
                    [0, 0],
                    [0.615, -0.128],
                    [0.441, -0.441],
                    [0, 0],
                    [0.024, -0.126],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0, 0],
                    [0.12, 0.607],
                    [0.441, 0.441],
                    [0, 0],
                    [0.127, 0.027],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1]
                  ],
                  v: [
                    [4.551, 0.549],
                    [3.284, 0.806],
                    [3.284, 0.806],
                    [1.665, 1.678],
                    [0.806, 3.284],
                    [0.549, 4.551],
                    [0.357, 4.873],
                    [0, 5],
                    [-0.357, 4.873],
                    [-0.549, 4.551],
                    [-0.806, 3.284],
                    [-1.665, 1.678],
                    [-3.284, 0.806],
                    [-4.551, 0.549],
                    [-4.874, 0.352],
                    [-5, 0],
                    [-4.874, -0.352],
                    [-4.551, -0.549],
                    [-3.284, -0.806],
                    [-1.665, -1.678],
                    [-0.806, -3.284],
                    [-0.549, -4.551],
                    [-0.357, -4.873],
                    [0, -5],
                    [0.357, -4.873],
                    [0.549, -4.551],
                    [0.806, -3.284],
                    [1.665, -1.678],
                    [3.284, -0.806],
                    [4.551, -0.549],
                    [4.874, -0.352],
                    [5, 0],
                    [4.874, 0.352]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 32, s: [90] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [50, 153, 0],
              to: [0, -8, 0],
              ti: [0, 8, 0]
            },
            { t: 29, s: [50, 105, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 19,
              s: [237, 237, 100]
            },
            { t: 25, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.076, -0.016],
                    [0, 0],
                    [0, 0],
                    [0.264, -0.264],
                    [0.072, -0.364],
                    [0, 0],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0.014, 0.076],
                    [0, 0],
                    [0.264, 0.264],
                    [0.369, 0.077],
                    [0, 0],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06],
                    [-0.076, 0.016],
                    [0, 0],
                    [-0.264, 0.264],
                    [-0.072, 0.364],
                    [0, 0],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [-0.014, -0.076],
                    [0, 0],
                    [-0.264, -0.264],
                    [-0.369, -0.077],
                    [0, 0],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.369, 0.077],
                    [-0.264, 0.264],
                    [0, 0],
                    [-0.014, 0.076],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [0, 0],
                    [-0.072, -0.364],
                    [-0.264, -0.264],
                    [0, 0],
                    [-0.076, -0.016],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06],
                    [0, 0],
                    [0.369, -0.077],
                    [0.264, -0.264],
                    [0, 0],
                    [0.014, -0.076],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0, 0],
                    [0.072, 0.364],
                    [0.264, 0.264],
                    [0, 0],
                    [0.076, 0.016],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06]
                  ],
                  v: [
                    [2.731, 0.329],
                    [1.97, 0.483],
                    [1.97, 0.483],
                    [0.999, 1.006],
                    [0.483, 1.97],
                    [0.329, 2.73],
                    [0.214, 2.924],
                    [0, 3],
                    [-0.214, 2.924],
                    [-0.329, 2.73],
                    [-0.483, 1.97],
                    [-0.999, 1.006],
                    [-1.97, 0.483],
                    [-2.731, 0.329],
                    [-2.924, 0.211],
                    [-3, 0],
                    [-2.924, -0.211],
                    [-2.731, -0.329],
                    [-1.97, -0.483],
                    [-0.999, -1.006],
                    [-0.483, -1.97],
                    [-0.329, -2.73],
                    [-0.214, -2.924],
                    [0, -3],
                    [0.214, -2.924],
                    [0.329, -2.73],
                    [0.483, -1.97],
                    [0.999, -1.006],
                    [1.97, -0.483],
                    [2.731, -0.329],
                    [2.924, -0.211],
                    [3, 0],
                    [2.924, 0.211]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 20,
              s: [-100]
            },
            { t: 75, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 20,
              s: [300, 198, 0],
              to: [0, -18, 0],
              ti: [0, 18, 0]
            },
            { t: 76, s: [300, 90, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 22,
              s: [0, 0, 100]
            },
            { t: 28, s: [85, 85, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.089, -0.019],
                    [0, 0],
                    [0, 0],
                    [0.309, -0.309],
                    [0.084, -0.425],
                    [0, 0],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0.017, 0.088],
                    [0, 0],
                    [0.309, 0.309],
                    [0.43, 0.09],
                    [0, 0],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07],
                    [-0.089, 0.019],
                    [0, 0],
                    [-0.309, 0.309],
                    [-0.084, 0.425],
                    [0, 0],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [-0.017, -0.088],
                    [0, 0],
                    [-0.309, -0.309],
                    [-0.43, -0.09],
                    [0, 0],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.43, 0.09],
                    [-0.309, 0.309],
                    [0, 0],
                    [-0.017, 0.088],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [0, 0],
                    [-0.084, -0.425],
                    [-0.309, -0.309],
                    [0, 0],
                    [-0.089, -0.019],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07],
                    [0, 0],
                    [0.43, -0.09],
                    [0.309, -0.309],
                    [0, 0],
                    [0.017, -0.088],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0, 0],
                    [0.084, 0.425],
                    [0.309, 0.309],
                    [0, 0],
                    [0.089, 0.019],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07]
                  ],
                  v: [
                    [3.186, 0.384],
                    [2.299, 0.564],
                    [2.299, 0.564],
                    [1.166, 1.174],
                    [0.564, 2.299],
                    [0.384, 3.186],
                    [0.25, 3.411],
                    [0, 3.5],
                    [-0.25, 3.411],
                    [-0.384, 3.186],
                    [-0.564, 2.299],
                    [-1.166, 1.174],
                    [-2.299, 0.564],
                    [-3.186, 0.384],
                    [-3.412, 0.246],
                    [-3.5, 0],
                    [-3.412, -0.246],
                    [-3.186, -0.384],
                    [-2.299, -0.564],
                    [-1.166, -1.174],
                    [-0.564, -2.299],
                    [-0.384, -3.186],
                    [-0.25, -3.411],
                    [0, -3.5],
                    [0.25, -3.411],
                    [0.384, -3.186],
                    [0.564, -2.299],
                    [1.166, -1.174],
                    [2.299, -0.564],
                    [3.186, -0.384],
                    [3.412, -0.246],
                    [3.5, 0],
                    [3.412, 0.246]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.898010253906, 0.898010253906, 0.898010253906, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 20,
      op: 1033,
      st: 33,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 41,
              s: [-80]
            },
            { t: 78, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 41,
              s: [68, 134, 0],
              to: [-0.167, -8.333, 0],
              ti: [0.167, 8.333, 0]
            },
            { t: 79, s: [67, 84, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 45,
              s: [0, 0, 100]
            },
            { t: 51, s: [83, 83, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.127, -0.027],
                    [0, 0],
                    [0, 0],
                    [0.441, -0.441],
                    [0.12, -0.607],
                    [0, 0],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0.024, 0.126],
                    [0, 0],
                    [0.441, 0.441],
                    [0.615, 0.128],
                    [0, 0],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1],
                    [-0.127, 0.027],
                    [0, 0],
                    [-0.441, 0.441],
                    [-0.12, 0.607],
                    [0, 0],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [-0.024, -0.126],
                    [0, 0],
                    [-0.441, -0.441],
                    [-0.615, -0.128],
                    [0, 0],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.615, 0.128],
                    [-0.441, 0.441],
                    [0, 0],
                    [-0.024, 0.126],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [0, 0],
                    [-0.12, -0.607],
                    [-0.441, -0.441],
                    [0, 0],
                    [-0.127, -0.027],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1],
                    [0, 0],
                    [0.615, -0.128],
                    [0.441, -0.441],
                    [0, 0],
                    [0.024, -0.126],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0, 0],
                    [0.12, 0.607],
                    [0.441, 0.441],
                    [0, 0],
                    [0.127, 0.027],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1]
                  ],
                  v: [
                    [4.551, 0.549],
                    [3.284, 0.806],
                    [3.284, 0.806],
                    [1.665, 1.678],
                    [0.806, 3.284],
                    [0.549, 4.551],
                    [0.357, 4.873],
                    [0, 5],
                    [-0.357, 4.873],
                    [-0.549, 4.551],
                    [-0.806, 3.284],
                    [-1.665, 1.678],
                    [-3.284, 0.806],
                    [-4.551, 0.549],
                    [-4.874, 0.352],
                    [-5, 0],
                    [-4.874, -0.352],
                    [-4.551, -0.549],
                    [-3.284, -0.806],
                    [-1.665, -1.678],
                    [-0.806, -3.284],
                    [-0.549, -4.551],
                    [-0.357, -4.873],
                    [0, -5],
                    [0.357, -4.873],
                    [0.549, -4.551],
                    [0.806, -3.284],
                    [1.665, -1.678],
                    [3.284, -0.806],
                    [4.551, -0.549],
                    [4.874, -0.352],
                    [5, 0],
                    [4.874, 0.352]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 39,
      op: 1049,
      st: 49,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 29,
              s: [-90]
            },
            { t: 77, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 29,
              s: [50, 270.5, 0],
              to: [0, -19.583, 0],
              ti: [0, 19.583, 0]
            },
            { t: 78, s: [50, 153, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 35,
              s: [0, 0, 100]
            },
            { t: 41, s: [237, 237, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.076, -0.016],
                    [0, 0],
                    [0, 0],
                    [0.264, -0.264],
                    [0.072, -0.364],
                    [0, 0],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0.014, 0.076],
                    [0, 0],
                    [0.264, 0.264],
                    [0.369, 0.077],
                    [0, 0],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06],
                    [-0.076, 0.016],
                    [0, 0],
                    [-0.264, 0.264],
                    [-0.072, 0.364],
                    [0, 0],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [-0.014, -0.076],
                    [0, 0],
                    [-0.264, -0.264],
                    [-0.369, -0.077],
                    [0, 0],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.369, 0.077],
                    [-0.264, 0.264],
                    [0, 0],
                    [-0.014, 0.076],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [0, 0],
                    [-0.072, -0.364],
                    [-0.264, -0.264],
                    [0, 0],
                    [-0.076, -0.016],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06],
                    [0, 0],
                    [0.369, -0.077],
                    [0.264, -0.264],
                    [0, 0],
                    [0.014, -0.076],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0, 0],
                    [0.072, 0.364],
                    [0.264, 0.264],
                    [0, 0],
                    [0.076, 0.016],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06]
                  ],
                  v: [
                    [2.731, 0.329],
                    [1.97, 0.483],
                    [1.97, 0.483],
                    [0.999, 1.006],
                    [0.483, 1.97],
                    [0.329, 2.73],
                    [0.214, 2.924],
                    [0, 3],
                    [-0.214, 2.924],
                    [-0.329, 2.73],
                    [-0.483, 1.97],
                    [-0.999, 1.006],
                    [-1.97, 0.483],
                    [-2.731, 0.329],
                    [-2.924, 0.211],
                    [-3, 0],
                    [-2.924, -0.211],
                    [-2.731, -0.329],
                    [-1.97, -0.483],
                    [-0.999, -1.006],
                    [-0.483, -1.97],
                    [-0.329, -2.73],
                    [-0.214, -2.924],
                    [0, -3],
                    [0.214, -2.924],
                    [0.329, -2.73],
                    [0.483, -1.97],
                    [0.999, -1.006],
                    [1.97, -0.483],
                    [2.731, -0.329],
                    [2.924, -0.211],
                    [3, 0],
                    [2.924, 0.211]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 29,
      op: 1029,
      st: 29,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      parent: 8,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 360, ix: 10 },
        p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0, 5],
                    [0, -5]
                  ],
                  c: false
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [0, 5],
                        [0, -5]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [-100, -100], ix: 3 },
                  r: { a: 0, k: -90, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 1,
              cix: 2,
              bm: 0,
              ix: 2,
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.730743408203, 0.730743408203, 0.730743408203, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 5,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [128.5, 224.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 5,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 42,
              s: [80, 80, 100]
            },
            { t: 72, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          np: 5,
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              ix: 1,
              v: { a: 0, k: 20, ix: 1 }
            },
            {
              ty: 0,
              ix: 2,
              v: { a: 0, k: 40, ix: 2 }
            },
            {
              ty: 0,
              ix: 3,
              v: { a: 0, k: 60, ix: 3 }
            }
          ]
        }
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.603, 1.454],
                    [0.001, 1.573],
                    [0.6, 1.458],
                    [1.115, 1.117],
                    [1.459, 0.605],
                    [1.579, 0],
                    [1.005, -0.291],
                    [1.62, -2.404],
                    [-0.362, -2.875],
                    [-2.165, -1.93],
                    [-2.902, -0.034],
                    [0, 0],
                    [-1.457, 0.601],
                    [-1.115, 1.113]
                  ],
                  o: [
                    [0.603, -1.454],
                    [0.006, -1.577],
                    [-0.6, -1.458],
                    [-1.115, -1.117],
                    [-1.459, -0.605],
                    [-1.047, -0.011],
                    [-2.804, 0.75],
                    [-1.62, 2.404],
                    [0.362, 2.875],
                    [2.165, 1.93],
                    [0, 0],
                    [1.576, 0.001],
                    [1.457, -0.601],
                    [1.115, -1.113]
                  ],
                  v: [
                    [11.089, 4.607],
                    [12, 0.021],
                    [11.099, -4.576],
                    [8.501, -8.477],
                    [4.602, -11.084],
                    [0, -11.999],
                    [-3.095, -11.576],
                    [-9.956, -6.684],
                    [-11.906, 1.503],
                    [-7.987, 8.955],
                    [-0.127, 12],
                    [0, 12],
                    [4.594, 11.091],
                    [8.488, 8.495]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.960784316063, 0.964705884457, 0.960784316063, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [182.5, 176.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -3.275],
                    [0, 0],
                    [0.827, -0.931],
                    [1.236, -0.07],
                    [0, 0],
                    [1.058, 1.19],
                    [-0.08, 1.598],
                    [0, 0],
                    [-0.711, 0.799],
                    [-1.062, 0.06],
                    [0, 0],
                    [-0.65, -0.529],
                    [-0.206, -0.817]
                  ],
                  o: [
                    [0, 0],
                    [4.296, 0],
                    [0, 0],
                    [0.062, 1.25],
                    [-0.827, 0.931],
                    [0, 0],
                    [-1.581, -0.09],
                    [-1.058, -1.19],
                    [0, 0],
                    [-0.054, -1.074],
                    [0.711, -0.799],
                    [0, 0],
                    [0.833, 0.029],
                    [0.65, 0.529],
                    [0, 0]
                  ],
                  v: [
                    [3.065, -12.583],
                    [17.198, -12.583],
                    [21.494, -4.444],
                    [21.494, 15.033],
                    [20.3, 18.437],
                    [17.078, 20],
                    [-15.844, 20],
                    [-19.965, 18.001],
                    [-21.492, 13.647],
                    [-21.492, -15.733],
                    [-20.466, -18.657],
                    [-17.699, -20],
                    [-1.9, -20],
                    [0.384, -19.141],
                    [1.702, -17.069]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [168.5, 168.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [72, 72], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
